import React, { useContext } from "react";
import { Link, graphql, StaticQuery } from "gatsby";

import { getLangTerm } from "../lang";
import { getLangPath } from "../utils/lang";

import { SiteContext } from "./Layout"

const Sidebar = () => {

    const {
        lang,
        langData,
        routeSlugs,
        pagePath
    } = useContext(SiteContext);

    const langPath = getLangPath(lang);

    const renderNewsAndBlog = () => {
        return <>
            <StaticQuery
                query={graphql`
                    query {
                        news:allFile(
                            filter: {sourceInstanceName: {eq: "news"}}
                            sort: {fields: childrenMdx___frontmatter___date, order: DESC}
                            limit: 3
                        ) {
                            edges {
                                node {
                                    id
                                    childMdx {
                                        id
                                        slug
                                        frontmatter {
                                            date(formatString: "MMM DD, YYYY")
                                            title
                                        }
                                    }
                                }
                            }
                        }
                        blog:allFile(
                            filter: {sourceInstanceName: {eq: "blog"}}
                            sort: {fields: childrenMdx___frontmatter___date, order: DESC}
                            limit: 2
                        ) {
                            edges {
                                node {
                                    id
                                    childMdx {
                                        id
                                        slug
                                        frontmatter {
                                            date(formatString: "MMM DD, YYYY")
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                `}
                render={data => {
                    return <>
                        <h2>Recent News</h2>
                        <ul className="news">
                            { data.news.edges.map((edge, i) => {
                                const childMdx = edge.node.childMdx;
                                const { slug  } = childMdx;
                                const { title, date} = childMdx.frontmatter;
                                const isHighlight = pagePath && pagePath.indexOf(slug) > -1;
                
                                return <li key={i}>
                                    <Link
                                        to={`/news/${slug}`}
                                        className={`${ isHighlight ? "highlight" : "" }`}
                                    >
                                        {title}
                                        <span className="date">{date}</span>
                                    </Link>
                                </li>
                            })}
                        </ul>
                        <h2>Recent Blog Posts</h2>
                        <ul className="blog">
                            { data.blog.edges.map((edge, i) => {
                                const childMdx = edge.node.childMdx;
                                const { slug  } = childMdx;
                                const { title, date} = childMdx.frontmatter;
                                return <li key={i}>
                                    <Link
                                        to={`/blog/${slug}`}
                                    >
                                        {title}
                                        <span className="date">{date}</span>
                                    </Link>
                                </li>
                            })}
                        </ul>
                    </>;
                }}
            />
        </>
    }

    return <div className="sidebar">
        <h2>{ getLangTerm("routes", lang) }</h2>
        <ul className="routes">
            { routeSlugs && routeSlugs.map((route, i) => {
                const routeLangData = langData[`${lang}-${route.slug}`];
                const isHighlight = pagePath && pagePath.indexOf(`/routes/${route.slug}`) > -1;
                return <li key={i}>
                    <Link
                        to={`${langPath}/routes/${route.slug}`}
                        className={`${ isHighlight ? "highlight" : "" }`}
                    >
                        {routeLangData.title}
                    </Link>
                </li>
            })}
        </ul>
        { renderNewsAndBlog() }
    </div>
};
export default Sidebar;
