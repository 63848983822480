import React from 'react';
import { graphql, Link } from 'gatsby';

import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Seo from '../components/Seo';

const NewsPage = ({ data, pageContext }) => {
    const { news } = data;
    const { edges: newsData }  = news;
    const { numPages, currentPage } = pageContext;
 
    const getNewsLink = ({ targetPage }) => {
        return  `/news${ targetPage > 1 ? `-${targetPage}` : "" }`
    }

    return (
        <Layout 
            pageContext={pageContext}
        >
            <Seo title="News Articles" />
            <div className="sidebar-container">
                <Sidebar />
                <div className="sidebar-content post-links">
                    { newsData.map((newsItem, i) => {
                        const childMdx = newsItem.node.childMdx;
                        const { title, date } = childMdx.frontmatter;
                        const { excerpt, slug } = childMdx;
                        return <div key={i} className="post-item">
                            <Link                                
                                to={`/news/${slug}`}
                            >
                                <h1>{title}</h1>
                            </Link>
                            <span className="date">{date}</span>
                            <p className="content">{excerpt}</p>
                            <Link
                                className="btn"
                                to={`/news/${slug}`}
                            >
                                Read More
                            </Link>
                        </div>
                    }) }
                    <div className="page-links">
                        { currentPage > 1 && <Link
                            className="btn prev"
                            to={getNewsLink({
                                targetPage: currentPage - 1
                            })}
                        >
                            Previous News
                        </Link> }
                        { currentPage < numPages && <Link
                            className="btn next"
                            to={getNewsLink({
                                targetPage: currentPage + 1
                            })}
                        >
                            More News
                        </Link> }
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default NewsPage;

export const query = graphql`
    query newsListQuery($skip: Int!, $limit: Int!){
        news:allFile(
            filter: {sourceInstanceName: {eq: "news"}}
            sort: {fields: childrenMdx___frontmatter___date, order: DESC}
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    id
                    childMdx {
                        id
                        slug
                        body
                        excerpt
                        frontmatter {
                            date(formatString: "MMM DD, YYYY")
                            title
                        }
                    }
                }
                next {
                    id
                }
                previous {
                    id
                }
            }
        }
    }
`;